import React, { useState } from 'react'
import { Link } from 'gatsby'
import * as styles from '../styles/leistungen-nav.module.css'
import CaretRight from '../assets/icons/CaretRight'

export default function LeistungenNav() {
  return (
    <div className={styles.container}>
      <Link activeClassName={styles.active} to="/recall-system">
        <CaretRight color="#53a447" height={30} />
        Recall-System
      </Link>
      <Link
        activeClassName={styles.active}
        to="/kinder-und-alterszahnheilkunde"
      >
        <CaretRight color="#53a447" height={30} />
        Kinder und Alterszahnheilkunde
      </Link>
      <Link activeClassName={styles.active} to="/individuelle-prophylaxe">
        <CaretRight color="#53a447" height={30} />
        Individuelle Prophylaxe
      </Link>
      <Link activeClassName={styles.active} to="/asthetische-zahnmedizin">
        <CaretRight color="#53a447" height={30} />
        Ästhetische Zahnmedizin
      </Link>
      <Link activeClassName={styles.active} to="/amalgamentfernung">
        <CaretRight color="#53a447" height={30} />
        Amalgamentfernung
      </Link>
      <Link activeClassName={styles.active} to="/moderne-endodonite">
        <CaretRight color="#53a447" height={30} />
        Moderne Endodonite
      </Link>
      <Link activeClassName={styles.active} to="/parodontosebehandlung">
        <CaretRight color="#53a447" height={30} />
        Parodontosebehandlung
      </Link>
      <Link activeClassName={styles.active} to="/digitale-röntgentechnik">
        <CaretRight color="#53a447" height={30} />
        Digitale Röntgentechnik
      </Link>
      <Link activeClassName={styles.active} to="/prothetik-und-implantologie">
        <CaretRight color="#53a447" height={30} />
        Prothetik und Implantologie
      </Link>
      <Link activeClassName={styles.active} to="/individuelle-schienen">
        <CaretRight color="#53a447" height={30} />
        Individuelle Schienen
      </Link>
      <Link activeClassName={styles.active} to="/lupenbrille">
        <CaretRight color="#53a447" height={30} />
        Lupenbrille
      </Link>
      <Link activeClassName={styles.active} to="/haus-und-pflegeheimbetreuung">
        <CaretRight color="#53a447" height={30} />
        Haus und Pflegeheimbetreuung
      </Link>
    </div>
  )
}
