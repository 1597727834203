import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../styles/leistungen-details.module.css'
import LeistungenNav from '../components/LeistungenNav'
import { graphql } from 'gatsby'
import Termin from '../components/Termin'

export default function LeistungenDetails({ data }) {
  const { html } = data.markdownRemark
  const { title, image } = data.markdownRemark.frontmatter
  return (
    <Layout>
      <div className={styles.leistungenContainer}>
        <div
          className={styles.leistungenImg}
          style={{ backgroundImage: `url(${image})` }}
        ></div>

        <div className={styles.containerContent}>
          <div
            className={styles.innerContent}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <LeistungenNav className={styles.leistungenNav} />
        </div>
        <Termin />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Leistung($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        image
        title
      }
    }
  }
`
